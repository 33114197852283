import React from "react";
import { Col } from "antd";

import * as classes from "./Explore.module.scss";
import yellowTickIcon from "../images/yellow-tick-icon.svg";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const SkillCourse = () => {
  return (
    <>
      <Col className={classes.productLineContent}>
        <Col className={classes.left}>
          <h1 className={classes.headingDesktop}>
            Learn Your Way to Success with <br /> <span>150+</span> Skill
            Courses
          </h1>
          <h1 className={classes.headingTablet}>
            Learn Your Way to Success with <span>150+</span> Skill Courses
          </h1>
          <Col className={classes.features}>
            <Col>
              <img src={yellowTickIcon} />
              New Courses Every
            </Col>
            <Col>
              <img src={yellowTickIcon} />
              Month On-Demand Industry Skills
            </Col>
            <Col>
              <img src={yellowTickIcon} />
              Completion Certificate
            </Col>
          </Col>
          <CustomButton
            title={"Explore Courses"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              window.open("/skill-courses");
            }}
          />
        </Col>
        <Col className={classes.buttonWrapper}>
          <CustomButton
            title={"Explore Careeer"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              window.open("/skill-courses");
            }}
          />
        </Col>
        <Col className={classes.right}>
          <img
            src={`${HOME_LANDING_PAGE_FILES_PATH}/skill-course-explore.webp`}
            loading="lazy"
          />
        </Col>
      </Col>
    </>
  );
};

export default SkillCourse;
