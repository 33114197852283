import React from "react";
import { Col } from "antd";

import * as classes from "./Reviews.module.scss";

const ReviewerCard = (props) => {
  const { reviewer } = props;

  return (
    <Col className={classes.reviewerCard}>
      <img src={reviewer.image} alt="Unschool" loading="lazy" />
      <img src={reviewer.companyLogo} alt="Unschool" loading="lazy" />
    </Col>
  );
};

export default ReviewerCard;
