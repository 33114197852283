// extracted by mini-css-extract-plugin
export var container = "Reviews-module--container--edc7f";
export var controlButtons = "Reviews-module--controlButtons--0a18c";
export var lmsScrollbar = "Reviews-module--lms-scrollbar--fdd83";
export var reviewCard = "Reviews-module--reviewCard--f7476";
export var reviewCardBottom = "Reviews-module--reviewCardBottom--3bcec";
export var reviewCardTop = "Reviews-module--reviewCardTop--448aa";
export var reviewerCard = "Reviews-module--reviewerCard--4b108";
export var reviewers = "Reviews-module--reviewers--2d4d0";
export var reviewersContainer = "Reviews-module--reviewersContainer--84e10";
export var reviewsDesktop = "Reviews-module--reviewsDesktop--6dd7a";
export var reviewsTablet = "Reviews-module--reviewsTablet--b64b2";
export var wrapper = "Reviews-module--wrapper--f0c67";