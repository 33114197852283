import React, { useEffect } from "react";
import { Col } from "antd";

import * as classes from "./Achivements.module.scss";

const AchievementCard = (props) => {
  const { achievement } = props;

  useEffect(() => {
    setTimeout(() => {
      const caseStudyItems = document.getElementsByClassName(
        classes.achievementCardWrapper
      );
      let maxEleHeight = 0;
      for (let i = 0; i < caseStudyItems.length; i++) {
        if (caseStudyItems[i].offsetHeight > maxEleHeight) {
          maxEleHeight = caseStudyItems[i].offsetHeight;
        }
      }
      for (let i = 0; i < caseStudyItems.length; i++) {
        caseStudyItems[i].style.height = `${maxEleHeight}px`;
      }
    }, 1000);
  }, []);

  return (
    <Col className={classes.achievementCard}>
      <Col className={classes.achievementCardWrapper}>
        <img src={achievement.image} alt="Unschool" loading="lazy" />
        <p>{achievement.content}</p>
        <a href={achievement.href} target="_blank">
          Read More
        </a>
      </Col>
    </Col>
  );
};

export default AchievementCard;
