// extracted by mini-css-extract-plugin
export var courseImage = "SearchCourses-module--courseImage--446a5";
export var courseImageContainer = "SearchCourses-module--courseImageContainer--d5412";
export var courseResultListContainer = "SearchCourses-module--courseResultListContainer--3077c";
export var courseResultListitem = "SearchCourses-module--courseResultListitem--11c1d";
export var courseTitle = "SearchCourses-module--courseTitle--c8bb7";
export var lmsScrollbar = "SearchCourses-module--lms-scrollbar--6d297";
export var searchCourses = "SearchCourses-module--searchCourses--ff8b2";
export var searchCoursesInputContainer = "SearchCourses-module--searchCoursesInputContainer--6d59c";
export var searchIcon = "SearchCourses-module--searchIcon--0e955";
export var searchResultLable = "SearchCourses-module--searchResultLable--ac074";