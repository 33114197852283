import React from "react";
import { Col } from "antd";

import * as classes from "./Explore.module.scss";
import yellowTickIcon from "../images/yellow-tick-icon.svg";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const JobProgram = () => {
  return (
    <>
      <Col className={classes.productLineContent}>
        <Col className={classes.left}>
          <h1 className={classes.headingDesktop}>
            Learn while we get <br /> you <span>job-ready</span>
          </h1>
          <h1 className={classes.headingTablet}>
            Learn while we get you <span>job-ready</span>
          </h1>
          <Col className={classes.features}>
            <Col>
              <img src={yellowTickIcon} />
              Cohort based Live Classes
            </Col>
            <Col>
              <img src={yellowTickIcon} />
              Career Assistance & Placements
            </Col>
            <Col>
              <img src={yellowTickIcon} />
              Industry Case Studies and Capstone Projects
            </Col>
          </Col>
          <CustomButton
            title={"Apply Now"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              window.open("/job-program/advance-sales-certification");
            }}
          />
        </Col>
        <Col className={classes.buttonWrapper}>
          <CustomButton
            title={"Apply Now"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              window.open("job-program/advance-sales-certification");
            }}
          />
        </Col>
        <Col className={classes.right}>
          <img
            src={`${HOME_LANDING_PAGE_FILES_PATH}/job-program-explore.webp`}
            loading="lazy"
          />
        </Col>
      </Col>
    </>
  );
};

export default JobProgram;
