import React from "react";
import { Col } from "antd";

import * as classes from "./Hero.module.scss";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const Hero = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.content}>
          <Col>
            <Col className={classes.titleContainer}>
              <h1>
                India’s First Streaming <br /> Platform for Learning
              </h1>
              <p>
                A platform to learn new things, upskill your career,
                <br /> and get placed.
              </p>
            </Col>
            <Col className={classes.buttonWrapper}>
              <a href="#home-explore-section">
                <CustomButton
                  title={"Explore Programs"}
                  variant={variants.primaryButton}
                  customClass={classes.buttonCustomClass}
                  customTextClass={classes.buttonCustomTextClass}
                />
              </a>
            </Col>
          </Col>
          <Col className={classes.achievements}>
            <Col>
              <pre>Approved Training Partner</pre>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/nsdc.png`}
                loading="lazy"
              />
            </Col>
            <Col>
              <pre>LinkedIn Top Startups 2020</pre>
              <img
                src={`${HOME_LANDING_PAGE_FILES_PATH}/linkedIn-top-startup.png`}
                loading="lazy"
              />
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Hero;
