// extracted by mini-css-extract-plugin
export var active = "Course-module--active--1e13a";
export var categories = "Course-module--categories--15d79";
export var categoryName = "Course-module--categoryName--23d8b";
export var container = "Course-module--container--3a802";
export var controlButtons = "Course-module--controlButtons--3ba65";
export var courseCarousel = "Course-module--courseCarousel--c6548";
export var coursesCarouselDesktop = "Course-module--coursesCarouselDesktop--ddd07";
export var coursesCarouselMobile = "Course-module--coursesCarouselMobile--c2743";
export var coursesContentDesktop = "Course-module--coursesContentDesktop--dffe2";
export var coursesContentMobile = "Course-module--coursesContentMobile--d55cf";
export var lmsScrollbar = "Course-module--lms-scrollbar--53274";
export var searchContainer = "Course-module--searchContainer--b7b96";
export var title = "Course-module--title--9fdb0";
export var wrapper = "Course-module--wrapper--890f6";