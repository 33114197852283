// extracted by mini-css-extract-plugin
export var bottom = "LearningRoadMap-module--bottom--6bfc8";
export var buttonClassName = "LearningRoadMap-module--buttonClassName--0adad";
export var buttonCustomClass = "LearningRoadMap-module--buttonCustomClass--bf6a3";
export var buttonCustomTextClass = "LearningRoadMap-module--buttonCustomTextClass--d572d";
export var container = "LearningRoadMap-module--container--b1c53";
export var controlButtons = "LearningRoadMap-module--controlButtons--9cb23";
export var flowCurve = "LearningRoadMap-module--flowCurve--4252a";
export var gifWrapper = "LearningRoadMap-module--gifWrapper--d7aa3";
export var left = "LearningRoadMap-module--left--b4e38";
export var lmsScrollbar = "LearningRoadMap-module--lms-scrollbar--2a9b2";
export var middle = "LearningRoadMap-module--middle--18382";
export var right = "LearningRoadMap-module--right--4694a";
export var slideContent = "LearningRoadMap-module--slideContent--4da8b";
export var top = "LearningRoadMap-module--top--de23c";
export var wrapperDesktop = "LearningRoadMap-module--wrapperDesktop--07363";
export var wrapperMobile = "LearningRoadMap-module--wrapperMobile--3dd75";