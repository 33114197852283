// extracted by mini-css-extract-plugin
export var accordianHeader = "Faq-module--accordianHeader--50ce4";
export var accordianItem = "Faq-module--accordianItem--03645";
export var accordianText = "Faq-module--accordianText--141c8";
export var contactUnschool = "Faq-module--contactUnschool--47a2f";
export var courseFaqContainer = "Faq-module--courseFaqContainer--000c7";
export var courseFaqContent = "Faq-module--courseFaqContent--20276";
export var courseFaqContentLeft = "Faq-module--courseFaqContentLeft--6e217";
export var courseFaqContentRight = "Faq-module--courseFaqContentRight--9c5c3";
export var faqImageContainer = "Faq-module--faqImageContainer--d0469";
export var headingContainer = "Faq-module--headingContainer--58c3e";
export var headingDesktop = "Faq-module--headingDesktop--8ccea";
export var headingMobile = "Faq-module--headingMobile--c7d26";
export var iconClassName = "Faq-module--iconClassName--c4135";
export var lmsScrollbar = "Faq-module--lms-scrollbar--5eee9";