// extracted by mini-css-extract-plugin
export var buttonCustomClass = "Coaches-module--buttonCustomClass--f3102";
export var buttonCustomTextClass = "Coaches-module--buttonCustomTextClass--3df9c";
export var buttonMobile = "Coaches-module--buttonMobile--9db6b";
export var coach = "Coaches-module--coach--3bcf4";
export var coachCarousel = "Coaches-module--coachCarousel--124d7";
export var coachDetails = "Coaches-module--coachDetails--d846a";
export var coachName = "Coaches-module--coachName--dd49f";
export var companyLogo = "Coaches-module--companyLogo--e79c9";
export var container = "Coaches-module--container--b42a8";
export var controlButtons = "Coaches-module--controlButtons--809e4";
export var course = "Coaches-module--course--6e1f8";
export var lmsScrollbar = "Coaches-module--lms-scrollbar--ec988";