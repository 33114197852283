import { Col } from "antd";
import React from "react";

import CategoryCard from "./CategoryCard";
import { categoriesFooter } from "../Utils/data";
import * as classes from "./CourseCategories.module.scss";
import { useState } from "react";
import {
  convertArrayToHashMap,
  convertHashMapToArray,
} from "../../../utils/functions";
import { useEffect } from "react";

const FooterCourseCategories = (props) => {
  const { categoryCourseList } = props;

  const courses = convertArrayToHashMap(categoryCourseList.data, "slug");
  const [categoryCorouselList, setCategoryCorouselList] = useState({});

  useEffect(() => {
    const modifiedCategoriesList = {};
    convertHashMapToArray(categoriesFooter).forEach((item) => {
      if (item.slug === "vocational") {
        const filterArtAndHumanitiesCourses =
          courses["art-and-humanities"]?.courses?.nodes;
        const filterPersonalDevelopmentCourses =
          courses["personal-development"]?.courses?.nodes;
        return (modifiedCategoriesList["vocational"] = {
          ...item,
          courses: [
            ...filterArtAndHumanitiesCourses,
            ...filterPersonalDevelopmentCourses,
          ],
        });
      }
      if (item.slug === "all-categories") {
        return (modifiedCategoriesList["all-categories"] = {
          ...item,
          courses: [],
        });
      } else {
        const filterCourses = courses[item.slug]?.courses?.nodes;
        return (modifiedCategoriesList[item.slug] = {
          ...item,
          courses: filterCourses,
        });
      }
    });
    setCategoryCorouselList(modifiedCategoriesList);
  }, []);

  return (
    <Col className={classes.container} id="courseCategories">
      <Col className={classes.wrapper}>
        {convertHashMapToArray(categoryCorouselList).map((category) => (
          <CategoryCard key={category.slug} category={category} />
        ))}
      </Col>
    </Col>
  );
};

export default FooterCourseCategories;
