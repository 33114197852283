import React from "react";
import classNames from "classnames";
import Slider from "react-slick";

import * as classes from "./Course.module.scss";
import { Col } from "antd";
import CourseCard from "../CourseCard";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ReactSlickController } from "../../adaptors/ReactSlickController";

const CoursesCarouselMobile = (props) => {
  const { categories, selectedCategory, setSelectedCategory, courseList } =
    props;

  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
  };

  const handleClick = (slug) => {
    if (slug === "all-categories") {
      const element = document.getElementById("courseCategories");
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      setSelectedCategory(slug);
    }
  };

  useEffect(() => {
    const slickController = { ...sliderRef?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
  }, [sliderRef, courseList, courseList.length]);

  return (
    <Col className={classes.coursesCarouselMobile}>
      <Col className={classes.categories}>
        {categories.map((category) => {
          return (
            <p
              className={classNames(
                classes.categoryName,
                category.slug === selectedCategory && classes.active
              )}
              onClick={() => {
                handleClick(category.slug);
              }}
              key={category.id}
            >
              {category.title}
            </p>
          );
        })}
      </Col>
      <Col className={classes.courseCarousel}>
        <Slider ref={sliderRef} {...settings}>
          {courseList.map((course) => (
            <CourseCard key={course.slug} course={course} />
          ))}
        </Slider>
        <Col className={classes.controlButtons}>
          <ReactSlickController
            next={buttonsController.next}
            prev={buttonsController.prev}
            slickGoTo={buttonsController.slickGoTo}
            activeSlide={activeSlide}
            totalSlides={courseList.length}
            slidesToShow={1}
          />
        </Col>
      </Col>
    </Col>
  );
};

export default CoursesCarouselMobile;
