import React from "react";
import { Col, Tabs } from "antd";

import * as classes from "./Explore.module.scss";
import SkillCourse from "./SkillCourse";
import InternshipProgram from "./InternshipProgram";
import JobProgram from "./JobProgram";

const Explore = () => {
  return (
    <Col className={classes.container} id="home-explore-section">
      <Col className={classes.wrapper}>
        <h1>
          Explore<span>.</span> Upskill<span>.</span> Build
        </h1>
        <Col>
          <Tabs defaultActiveKey="item-2">
            <Tabs.TabPane tab="Skill Courses" key="item-1">
              <SkillCourse />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Internship Programs" key="item-2">
              <InternshipProgram />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Job Programs" key="item-3">
              <JobProgram />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Col>
    </Col>
  );
};

export default Explore;
