import React, { useState } from "react";
import { Col, Typography } from "antd";

import * as classes from "./Faq.module.scss";
import CustomAccordion from "../../adaptors/CustomAccordion";
import { faqs } from "../Utils/data";
import { HOME_LANDING_PAGE_FILES_PATH } from "../../../utils/localization";

const { Title, Paragraph } = Typography;

const Faq = () => {
  const [activeKey, setActiveKey] = useState(-1);

  return (
    <Col className={classes.courseFaqContainer}>
      <Col className={classes.courseFaqContent}>
        <Title className={classes.headingMobile}>
          Got A <br /> Question?
        </Title>
        <Col className={classes.courseFaqContentLeft}>
          <Title className={classes.headingDesktop}>
            Got A <br /> Question?
          </Title>
          <Col className={classes.faqImageContainer}>
            <img
              src={`${HOME_LANDING_PAGE_FILES_PATH}/faqImage-min.webp`}
              alt="Unschool"
              loading="lazy"
            />
          </Col>
        </Col>
        <Col className={classes.courseFaqContentRight}>
          {faqs?.map((faq, index) => (
            <CustomAccordion
              containerClassName={classes.accordianItem}
              headerClassName={classes.accordianHeader}
              textClassName={classes.accordianText}
              headingContainerClassName={classes.headingContainer}
              iconClassName={classes.iconClassName}
              key={index}
              header={faq.question}
              text={faq.answer}
              activeKey={activeKey}
              currentKey={index}
              setActiveKey={() =>
                setActiveKey((state) => (state === index ? -1 : index))
              }
            />
          ))}
          <Paragraph className={classes.contactUnschool}>
            Still stuck with your doubt? Reach out to us on support@unschool.in
          </Paragraph>
        </Col>
      </Col>
    </Col>
  );
};

export default Faq;
