import { Col } from "antd";
import React, { useState } from "react";

import * as classes from "./CourseCategories.module.scss";
import { INTERNSHIP_PROGRAM } from "../../../utils/localization";

const CategoryCard = (props) => {
  const { category } = props;
  const [showAllCourses, setShowAllCourses] = useState(5);

  const getCourseOutLink = (course) => {
    switch (course.productLine) {
      case INTERNSHIP_PROGRAM:
        return `/internship-program/${course.slug}`;
      default:
        return `/skill-courses/${course.slug}`;
    }
  };

  return (
    <Col className={classes.categoryCard}>
      <h3>{category.title}</h3>
      <Col className={classes.courses}>
        {category.courses.slice(0, showAllCourses).map((course) => {
          return (
            <a
              key={course.slug}
              href={getCourseOutLink(course)}
              target="_blank"
            >
              <span>&gt;</span>&nbsp;&nbsp;
              {course.title}
            </a>
          );
        })}
      </Col>
      {category.courses.length !== showAllCourses &&
        category.courses.length > 5 && (
          <p
            onClick={() => {
              setShowAllCourses(category.courses.length);
            }}
          >
            Read More &gt;
          </p>
        )}
    </Col>
  );
};

export default CategoryCard;
