import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";

import * as classes from "./Reviews.module.scss";
import ReviewerCard from "./ReviewerCard";
import ReviewCard from "./ReviewCard";
import { reviewers, reviewes } from "../Utils/data";
import Slider from "react-slick";
import { ReactSlickController } from "../../adaptors/ReactSlickController";
import { flatten } from "lodash";

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

const Reviews = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
    slidesToShow: null,
  });

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
      slidesToShow: slickController?.props?.slidesToShow,
    }));
  }, [ref]);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>
          Hear It From Our <span>Learners</span>
        </h1>
        <Col className={classes.reviewersContainer}>
          <Marquee>
            <Col className={classes.reviewers}>
              {flatten(Array(100).fill(reviewers)).map((reviewer, index) => {
                return <ReviewerCard key={index} reviewer={reviewer} />;
              })}
            </Col>
          </Marquee>
        </Col>
        <Col className={classes.reviewsDesktop}>
          <Col>
            {reviewes.map((review) => {
              return <ReviewCard key={review.id} review={review} />;
            })}
          </Col>
        </Col>
        <Col className={classes.reviewsTablet}>
          <Slider ref={ref} {...settings}>
            {reviewes.map((review) => {
              return <ReviewCard key={review.id} review={review} />;
            })}
          </Slider>
          <Col className={classes.controlButtons}>
            <ReactSlickController
              next={buttonsController.next}
              prev={buttonsController.prev}
              slickGoTo={buttonsController.slickGoTo}
              activeSlide={activeSlide}
              totalSlides={reviewes.length}
              slidesToShow={1}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Reviews;
